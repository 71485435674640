import React, { useEffect } from "react"

import Image from "gatsby-image"
import { Link, navigate } from "gatsby"
import dayjs from "dayjs"

import ReactDOM from 'react-dom';

export const BlogCard = ({ post }) => {

  //READ ARTICLE ID
  const queryString = typeof window !== "undefined" ? window.location.search : ""
  const urlParams = new URLSearchParams(queryString);
  var article_from_url = urlParams.get("article");
  //alert(article_from_url)

  if (article_from_url !== null) {

    document.getElementById("articles_preview_section").hidden = true;
    document.getElementById("article_section").hidden = false;


    //API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
    function httpRequest(address, reqType, asyncProc) {
      //var req = new XMLHttpRequest();
      var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
      if (asyncProc) { 
        req.onreadystatechange = function() { 
          if (this.readyState == 4) {
            asyncProc(this);
          } 
        };
      }
      //req.open(reqType, address, !(!asyncProc));
      var data = new FormData();
      data.append("id", article_from_url);
      typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
      //req.send();
      typeof window !== "undefined" ? req.send(data) : ""
    return req;
    }

    var req = httpRequest("https://api.checksolarcost.com/v1/getArticleByIdAI", "POST");  
    const response = req.responseText;
    //const response_obj = JSON.parse(response);
    const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
   
    var title = response_obj[0].title;
    var body_html = response_obj[0].body_html;

    //alert(body_html)

    ReactDOM.render(title, document.getElementById('title_area'));
    ReactDOM.render(<div dangerouslySetInnerHTML={{__html: body_html}}></div>, document.getElementById('article_area'));
    
  }

        

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    //xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "blog_ai/" + post.url)
  },[]);


  return (
          <>
            <article
            className="w-full h-auto py-12 border-b border-gray-300 outline-none focus:outline-none md:px-8 md:w-1/2 lg:px-0 lg:flex lg:items-center lg:justify-between"
            onClick={e => { addWebEvent("visit", "blog/" + post.url) } }
      
            >
            <div className="w-full md:h-64 lg:h-56">
            <img className="w-full md:h-64 lg:h-56 object-cover" src={post.image} alt="Modal blog thumbnail"></img>
            </div>
            <div className="w-full h-auto flex flex-col justify-center mt-6 lg:mt-0 lg:p-6">
              <span className="text-sm text-gray-700">
                  {dayjs(post.created_at).format("MMMM DD, YYYY")}
              </span>
              <h5 className="mt-4">{post.title}</h5>
              
              <Link
                to={`/blog_ai?article=${post.id}`}
                className="mt-6 italic font-bradford text-gray-500 underline cursor-pointer"
                
              >
                Read Article
                
              </Link>


            </div>
            </article>
    </>
  )
}
