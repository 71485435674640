import React, { useEffect, useState } from "react"

import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
//import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"
//import { useBannerContext } from "../context"
import "../templates/post.css"
import { BlogCard } from "../components/blog/blog-card_ai"
//import ArrowSubmit from "../components/images/arrow-submit"
//var dayjs = require('dayjs')

export default () => {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    //xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "blog")
  },[]);



  //API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
  function httpRequest(address, reqType, asyncProc) {
      //var req = new XMLHttpRequest();
      var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
      if (asyncProc) { 
        req.onreadystatechange = function() { 
          if (this.readyState == 4) {
            asyncProc(this);
          } 
        };
      }
      //req.open(reqType, address, !(!asyncProc));
      typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
      //req.send();
      typeof window !== "undefined" ? req.send() : ""
    return req;
    }

    var req = httpRequest("https://api.checksolarcost.com/v1/getArticlesAI", "POST");  
    const response = req.responseText;
    //const response_obj = JSON.parse(response);
    const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
    //const post_content = response_obj.map(post => {return (<BlogCard post={post}/>)}) 
    const post_content = typeof window !== "undefined" ? response_obj.map(post => {return (<BlogCard post={post}/>)}) : ""

  return (
    <>
      
      <SEO title="Blog" />
      <Layout>
        
          <h1 className="hidden">H1</h1>

          <div className="h-5"></div>
          <div className="mt-20 px-12 lg:px-24">
            <div id="articles_preview_section">
              <ul className="flex flex-row flex-wrap">
              
                  { post_content }
                  
              </ul>
          </div>

          <div id="article_section" hidden>
          
            <div className="h-5"></div>
              <div className="relative mt-20">
                <div className="flex justify-center items-center py-6">
                  <h1 className="tracking-wide font-light px-6 md:px-20 lg:px-40 xl:px-84 text-center"
                  id="title_area">
                  </h1>
                </div>
              </div>
              <div className="w-full border-t blog-main">
                <div
                  className="px-6 md:px-20 lg:px-80 xl:px-90 py-10 relative bg-white blog-body font-light tracking-wide"
                  id="article_area"
                ></div>
              </div>
              <div className="flex justify-center pb-20">
                <Link
                  to="/blog_ai"
                  onClick={() => {window.location.href="/blog_ai"}}
                  className="bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-4 text-center font-bradford1 tracking-wide"
                >
                  Back to Blog
                </Link>
              </div>
            
            </div>

          </div>
      </Layout>
      
    </>
  )
}